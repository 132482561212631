import { Box, Center, Flex } from '@chakra-ui/react';
import { useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { LoadingSpinner } from '../../components/misc/LoadingSpinner';
import { StepContainer } from '../../components/wizard/types/wizardStep';
import Wizard from '../../components/wizard/wizard';
import { WizardFormContainer } from '../../components/wizard/wizardFormContainer';
import { EntityModel } from '../../lib/EntityDesigner/models/EntityModel';
import { useEntityModelQuery } from '../../lib/EntityDesigner/services/getEntityModel';
import { saveEntity } from '../../lib/EntityDesigner/services/saveEntity';
import { getAxios } from '../../lib/axios';
import { FunnelDefinition } from './FunnelDefinition';
import GenericWizardStep from './steps/GenericWizardStep';
import { useWizardState } from './store/WizardStore';

const Funnel = () => {
  const entityName = 'Contact';
  const entityDefinition = useEntityModelQuery(entityName);
  const wizardStore = useWizardState();
  const steps: Array<StepContainer> = [];
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const isDevData = !!searchParams.get('devData');

  const onSubmit = () => {
    const entityModel: EntityModel = {
      entityName: entityName,
      data: wizardStore.formData,
      id: undefined
    };
    saveEntity(entityModel);
    getAxios().get(`/instantquote/complete/${entityModel.data.Contact.Email}`);
    navigate('/thankyou');
  };

  useEffect(() => {
    wizardStore.setTotalSteps(steps.length - 1);
  }, [entityDefinition.isFetched, steps.length]);

  if (entityDefinition.isFetched && entityDefinition.data !== undefined) {
    FunnelDefinition.Steps.forEach((value, index) => {
      steps.push({
        Component: (
          <WizardFormContainer
            onStepBackward={FunnelDefinition.onStepBackward}
            onStepForward={FunnelDefinition.onStepForward}
            steps={FunnelDefinition.Steps}
            stepDefinition={FunnelDefinition.Steps[index]}
            defaultData={isDevData && index == 0 ? FunnelDefinition.defaultData : null}
            onSubmit={onSubmit}>
            {value.CustomRenderer && <value.CustomRenderer stepDefinition={FunnelDefinition.Steps[index]} properties={entityDefinition.data.properties} />}
            {!value.CustomRenderer && <GenericWizardStep stepDefinition={FunnelDefinition.Steps[index]} properties={entityDefinition.data.properties} />}
          </WizardFormContainer>
        ),
        StepName: value.StepName,
        ProgressStepIndex: value.ProgressStepIndex
      });
    });

    return (
      <Flex direction={'column'} flex='1' w={'100%'} maxW={'100%'}>
        <Center>
          <Box w={['100%', '100%', '100%', '100%', '75%']}>
            <Wizard steps={steps} progressSteps={FunnelDefinition.ProgressSteps} />
          </Box>
        </Center>
      </Flex>
    );
  }
  return <LoadingSpinner />;
};

export default Funnel;
