import { Suspense } from 'react';
import { Outlet } from 'react-router-dom';
import { MainLayout } from './components/layout/mainLayout';

export const App = () => {
  return (
    <MainLayout>
      <Suspense fallback={<div className='h-full w-full flex items-center justify-center'>Loading....</div>}>
        <Outlet />
      </Suspense>
    </MainLayout>
  );
};
